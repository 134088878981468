import axios from 'axios';
import config from '../../../config';
import * as bridge from '../../../bridge';
import {
    INDEX__CHANGE_ALERT_BOX, INDEX__CHANGE_TASK_STATUS,
    INDEX__CLOCK,
    INDEX__INIT_LOAD_DATA, INDEX__AWARD_ADD
} from '../actionType';

export const initLoadData = () => {
    return async (dispatch) => {
        try {
            const result = await axios.all([
                axios.post(config.getTaskList),
                axios.post(config.getWeekClockRecord),
                axios.post(config.getCoinEarningsInfo)]);

            {
                const { code, message } = result[0].data;

                if (code !== 0) {
                    console.error(message);
                    return;
                }
            }

            {
                const { code, message } = result[1].data;
                if (code !== 0) {
                    console.error(message);
                    return;
                }
            }

            {
                const { code, message } = result[2].data;
                if (code !== 0) {
                    console.error(message);
                    return;
                }
            }

            dispatch({
                type: INDEX__INIT_LOAD_DATA,
                data: {
                    taskList: result[0].data.data,
                    clockRecord: result[1].data.data,
                    userData: result[2].data.data
                }
            });
        } catch (e) {
            console.error(e);
        }
    };
};

export const changeAlertBox = (status) => {
    return {
        type: INDEX__CHANGE_ALERT_BOX,
        data: status
    };
};

export const userClock = () => {
    return async (dispatch) => {
        try {
            const result = await axios.post(config.userClockUrl);

            const { code, message, data } = result.data;

            if (code !== 0) {
                console.error(message);
                return;
            }

            dispatch({
                type: INDEX__CLOCK,
                data: data
            });
        } catch (e) {
            console.error(e);
        }
    };
};

export const receive = (utid, taskKey, blockKey) => {
    return async (dispatch) => {
        try {
            const result = await axios.post(config.taskReceive, {
                utid: utid
            });

            const { code, message, data } = result.data;

            bridge.toastMessage(message);

            if (code !== 0) {
                console.error(message);
                return;
            }

            dispatch({
                type: INDEX__CHANGE_TASK_STATUS,
                data: {
                    status: 3,
                    receiveCoin: data.receiveCoin,
                    utid: utid
                }
            });
        } catch (e) {
            console.error(e);
        }
    };
};

export const executeQuery = (url, utid) => {
    return async (dispatch) => {
        try {
            const result = await axios.post(url, {
                utid: utid
            });

            const { code, message, data } = result.data;

            if (code !== 0) {
                console.error(message);
                return;
            }

            dispatch({
                type: INDEX__CHANGE_TASK_STATUS,
                data: {
                    status: data.status ? 2 : false,
                    receiveCoin: 0,
                    target: data.target,
                    current: data.current,
                    utid: utid
                }
            });
        } catch (e) {
            console.error(e);
        }
    };
};

export const awardAdd = (speed) => {
    return {
        type: INDEX__AWARD_ADD,
        data: {
            speed: speed
        }
    };
};
