import {
    INDEX__CHANGE_ALERT_BOX,
    INDEX__CHANGE_TASK_STATUS,
    INDEX__CLOCK,
    INDEX__INIT_LOAD_DATA,
    INDEX__AWARD_ADD
} from '../../store/actionType';

const initState = {
    clock: {
        data: [
            {
                date: '周一',
                flag: false
            },
            {
                date: '周二',
                flag: false
            },
            {
                date: '周三',
                flag: false
            },
            {
                date: '周四',
                flag: false
            },
            {
                date: '周五',
                flag: false
            },
            {
                date: '周六',
                flag: false
            },
            {
                date: '周日',
                flag: false
            }
        ],
        count: 0,
        currentFlag: false,
        alertMsgBox: false,
        msg: '',
        fbBtn: true,
        receiveCoin: 20,
        hasLoad: false,
        clockId: 0
    },
    task: {
        data: [],
        hasLoad: false
    },
    user: {
        coin: 0,
        todayCoin: 0
    }
};

const initLoadData = (state, action) => {
    const { clockRecord, taskList, userData } = action.data;

    state.clock.hasLoad = true;
    state.task.hasLoad = true;

    state.clock.data = clockRecord;
    state.task.data = taskList;

    state.user.coin = userData.coinNumber;
    state.user.todayCoin = userData.todayCoinNumber;

    state.clock.data.forEach((v) => {
        if (v.current) {
            state.clock.currentFlag = v.flag;
        }
        if (v.flag) {
            state.clock.count++;
        }
    });

    return state;
};

const changeAlertBox = (state, action) => {
    state.clock.alertMsgBox = action.data;
    return state;
};

const clock = (state, action) => {
    const { promptMessage, coinCount, clockId } = action.data;

    state.clock.msg = promptMessage;
    state.clock.receiveCoin = coinCount;
    state.clock.alertMsgBox = true;
    state.clock.currentFlag = true;
    state.clock.clockId = clockId;
    state.user.coin += coinCount;
    state.user.todayCoin += coinCount;
    state.clock.data.forEach((v) => {
        if (v.current) {
            v.flag = true;
        }
    });
    return state;
};

const changeTaskStatus = (state, action) => {
    const { receiveCoin, status, utid, target, current } = action.data;
    console.log(action.data);
    state.task.data.forEach((block, blockKey) => {
        block.taskList.forEach((task, taskKey) => {
            if (task.userTaskId === utid) {
                if (status !== false) {
                    task.status = status;
                }
                task.progress = {
                    target: target,
                    current: current
                };
            }
        });
    });

    state.user.coin += receiveCoin;
    state.user.todayCoin += receiveCoin;

    return state;
};

const awardAdd = (state, action) => {
    state.clock.fbBtn = false;
    state.clock.receiveCoin += action.data.speed;
    state.user.todayCoin += action.data.speed;
    return state;
};

export default (state = initState, action) => {
    const newState = JSON.parse(JSON.stringify(state));
    switch (action.type) {
    case INDEX__INIT_LOAD_DATA:
        return initLoadData(newState, action);
    case INDEX__CHANGE_ALERT_BOX:
        return changeAlertBox(newState, action);
    case INDEX__CLOCK:
        return clock(newState, action);
    case INDEX__CHANGE_TASK_STATUS:
        return changeTaskStatus(newState, action);
    case INDEX__AWARD_ADD:
        return awardAdd(newState, action);
    default:
        return state;
    }
};
