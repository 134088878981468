const DETAILS__PUSH_BOOK_INFO_DATA = 'DETAILS_PUSH_BOOK_INFO_DATA';
const DETAILS__CHANGE_LOADING = 'DETAILS__CHANGE_LOADING';
const DETAILS__CHANGE_MORE_INTRO = 'DETAILS__CHANGE_MORE_INTRO';
const DETAILS__PUSH_RECOMMEND_DATA = 'DETAILS__PUSH_RECOMMEND_DATA';
const DETAILS__CHANGE_USER_BOOKCASE_STATUS = 'DETAILS__CHANGE_USER_BOOKCASE_STATUS';
const DETAILS__DOWNLOAD_ARTICLE_PROGRESS = 'DETAILS__DOWNLOAD_ARTICLE_PROGRESS';

export {
    DETAILS__PUSH_BOOK_INFO_DATA,
    DETAILS__CHANGE_LOADING,
    DETAILS__CHANGE_MORE_INTRO,
    DETAILS__CHANGE_USER_BOOKCASE_STATUS,
    DETAILS__PUSH_RECOMMEND_DATA,
    DETAILS__DOWNLOAD_ARTICLE_PROGRESS
};
