import axios from 'axios';
import config from '../../../config';
import {
    DETAILS__PUSH_BOOK_INFO_DATA,
    DETAILS__CHANGE_LOADING,
    DETAILS__CHANGE_MORE_INTRO,
    DETAILS__PUSH_RECOMMEND_DATA,
    DETAILS__CHANGE_USER_BOOKCASE_STATUS,
    DETAILS__DOWNLOAD_ARTICLE_PROGRESS
} from '../actionType';

export const pushBookInfoData = (articleId) => {
    return async (dispatch) => {
        try {
            const reps = await axios.post(config.getBookInfoUrl, {
                aid: articleId
            });

            const { code, data, message } = reps.data;

            if (code !== 0) {
                console.error(message);
                return;
            }

            dispatch({
                type: DETAILS__PUSH_BOOK_INFO_DATA,
                data: data
            });
        } catch (e) {
            console.error(e);
        }
    };
};

export const changeLoading = (loading) => {
    return {
        type: DETAILS__CHANGE_LOADING,
        loading: loading
    };
};

export const changeMoreIntro = (data) => {
    return {
        type: DETAILS__CHANGE_MORE_INTRO,
        data: data
    };
};

export const changeUserBookCaseStatus = (data) => {
    return {
        type: DETAILS__CHANGE_USER_BOOKCASE_STATUS,
        data: data
    };
};

export const downloadArticleProgress = (data, bookId) => {
    return {
        type: DETAILS__DOWNLOAD_ARTICLE_PROGRESS,
        data: data,
        bookId: bookId
    };
};

export const pushRecommendData = (typeId) => {
    return async (dispatch) => {
        try {
            const reps = await axios.post(config.getTypeRecommendUrl, {
                typeId: typeId
            });
            const { code, data, message } = reps.data;

            if (code !== 0) {
                console.error(message);
                return;
            }

            dispatch({
                type: DETAILS__PUSH_RECOMMEND_DATA,
                data: data
            });
        } catch (e) {
            console.error(e);
        }
    };
};
