import axios from 'axios';
import qs from 'qs';
import * as bridge from './bridge';
import cnf from './config';

// 添加请求拦截器
axios.interceptors.request.use(
    config => {
        if (config.method === 'post') {
            config.data = config.data ? config.data : {};
            if (cnf.localhost) {
                config.data.mode = 'dev';
            } else {
                config.transformResponse = (res) => {
                    res = JSON.parse(res);
                    res.data = JSON.parse(bridge.dataDecryption(res.data));
                    return res;
                };
                const sendData = JSON.stringify(config.data);
                const encryptionData = bridge.dataEncryption(sendData);
                config.data = JSON.parse(encryptionData);
            }

            config.data.package = cnf.appBundle;
            config.data.token = cnf.token;
            config.data.href = window.location.href;
            config.data = qs.stringify(config.data);
        }

        console.log('%O', config);

        return config;
    },
    error => {
        console.log(error);
    }
);

// 添加响应拦截器
axios.interceptors.response.use((response) => {
    return response;
}, (error) => {
    console.log(error);
});
