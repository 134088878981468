/**
 * 命名规范:
 *      组件名 + __ + 操作
 */

const INDEX__INIT_LOAD_DATA = 'INDEX___INIT_LOAD_DATA';
const INDEX__CHANGE_ALERT_BOX = 'INDEX__CHANGE_ALERT_BOX';
const INDEX__CLOCK = 'INDEX__CLOCK';
const INDEX__CHANGE_TASK_STATUS = 'INDEX__CHANGE_TASK_STATUS';
const INDEX__AWARD_ADD = 'INDEX__AWARD_ADD';

export {
    INDEX__INIT_LOAD_DATA,
    INDEX__CHANGE_ALERT_BOX,
    INDEX__CLOCK,
    INDEX__CHANGE_TASK_STATUS,
    INDEX__AWARD_ADD
};
