import dsBridge from 'dsbridge';
import detailsStore from './details/store/index';
import fuliStore from './fuli/store/index';
import { changeUserBookCaseStatus, downloadArticleProgress } from './details/store/dispatch/details';
import { awardAdd, executeQuery } from './fuli/store/dispatch/index';

export const startRead = (siteId, articleId, chapterId, chapterName, check, lastUpdate, coverUrl, author, bookName) => {
    dsBridge.call('reader', {
        aid: articleId,
        cid: chapterId,
        cname: chapterName,
        checkcase: check,
        lastupdate: lastUpdate,
        siteid: siteId,
        author: author,
        coverpicurl: coverUrl,
        bookname: bookName
    });
};

export const openWindow = (url, title, showNav, showSafeArea, safeColor = '') => {
    dsBridge.call('infoSearch', {
        url: url,
        aname: title,
        view: {
            shownav: showNav,
            showsafearea: showSafeArea,
            safeareacolor: safeColor
        }
    });
};

/*
* type
*   category    => 分类
*   bookcase    => 书架
*   bookstore   => 书城
*   mine        => 我的
*   fuli        => 福利
* */
export const footerNavBar = (type) => {
    return dsBridge.call('footerNavBar', {
        type: type
    });
};

/**
 * site
 *  1 => 百度
 *  2 => 360
 *  3 => 搜狗
 *  4 => 必应
 *  5 => 神马
 *  6 => 九九
 *  7 => 800
 */
export const openBrowser = (keyword, site) => {
    return dsBridge.call('netSearch', {
        keyword: keyword,
        site: site
    });
};

export const needLogin = (url) => {
    dsBridge.call('login');
};

export const addBookcase = (articleId, method) => {
    dsBridge.call('addBookcase', { aid: articleId, method: method });
};

export const delBookcase = (articleId, method) => {
    dsBridge.call('delBookcase', { aid: articleId, method: method });
};

export const readChapterList = (siteId, articleId, articleName) => {
    dsBridge.call('readChapterList', {
        aid: articleId,
        name: articleName,
        siteid: siteId
    });
};

export const redirectMenu = (index) => {
    dsBridge.call('redirectMenu', { index: index });
};

export const shareMenu = (imgUrl, url, intro, title) => {
    dsBridge.call('shareMenu', {
        imgurl: imgUrl,
        url: url,
        intro: intro,
        title: title
    });
};

export const download = (articleName, articleId, siteId, lastUpdate) => {
    dsBridge.call('download', {
        bookname: articleName,
        aid: articleId,
        lastupdate: lastUpdate,
        siteid: siteId
    });
};

export const sendEvent = (data) => {
    if (data !== '') {
        return dsBridge.call('event', JSON.parse(data));
    }
};

export const dataEncryption = (data) => {
    return dsBridge.call('dataEncryption', {
        data: data,
        mode: 'default'
    });
};

export const dataDecryption = (data) => {
    return dsBridge.call('dataDecryption', {
        data: data,
        mode: 'default'
    });
};

export const openSearchPage = () => {
    return dsBridge.call('openSearchPage');
};

export const goBack = () => {
    return dsBridge.call('goBack');
};

export const awardDouble = (clockId) => {
    return dsBridge.call('awardDouble', {
        clockId: clockId
    });
};

export const sliderArea = (top, bottom) => {
    return dsBridge.call('sliderArea', {
        top: top,
        bottom: bottom
    });
};

export const toExchangePage = () => {
    return dsBridge.call('toExchangePage');
};

export const prompt = (message, title = '') => {
    return dsBridge.call('prompt', {
        title: title,
        message: message
    });
};

export const toastMessage = (message) => {
    return dsBridge.call('toastMsg', {
        message: message
    });
};

export const fullMatch = (keyword, isMatch) => {
    return dsBridge.call('searchResult', {
        keyword: keyword,
        isMatch: isMatch
    });
};

/* 正确失败都会调用 */
dsBridge.register('bookshelfAddHandel', function (articleId, data) {
    data = JSON.parse(data);
    if (data.code === 0) {
        detailsStore.dispatch(changeUserBookCaseStatus(1));
    } else {
        alert(data.message);
    }
});

dsBridge.register('bookshelfDelHandel', function (articleId, data) {
    data = JSON.parse(data);
    if (data.code === 0) {
        detailsStore.dispatch(changeUserBookCaseStatus(0));
    } else {
        alert(data.message);
    }
});

/* 下载小说的进度值, float */
dsBridge.register('downloadArticleProgress', function (bookId, progress) {
    progress = (parseFloat(progress) * 100).toFixed(2);
    detailsStore.dispatch(downloadArticleProgress(progress, parseInt(bookId)));
});

/* 奖励翻倍完成 */
dsBridge.register('showAwardDouble', function (coinCount = 0) {
    const speed = 2;
    let init = 0;
    const timer = setInterval(() => {
        init += speed;
        fuliStore.dispatch(awardAdd(speed));
        if (init === coinCount) {
            clearInterval(timer);
        }
    }, 30);
});

/* 刷新任务状态 */
dsBridge.register('flushTasksStatus', function () {
    const state = fuliStore.getState();
    const taskGroup = state.indexReducer.task.data;

    taskGroup.forEach((block, blockKey) => {
        block.taskList.forEach((task, taskKey) => {
            if (task.btn.query) {
                fuliStore.dispatch(executeQuery(task.btn.query, task.userTaskId, taskKey, blockKey));
            }
        });
    });
});
