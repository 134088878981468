import {
    DETAILS__PUSH_BOOK_INFO_DATA,
    DETAILS__CHANGE_LOADING,
    DETAILS__CHANGE_MORE_INTRO,
    DETAILS__PUSH_RECOMMEND_DATA,
    DETAILS__CHANGE_USER_BOOKCASE_STATUS,
    DETAILS__DOWNLOAD_ARTICLE_PROGRESS
} from '../../store/actionType';

const initState = {
    bookInfo: {},
    recommend: {
        hasLoad: false,
        data: []
    },
    hasLoad: false,
    loading: false,
    moreIntro: false,
    progress: 0
};

const pushBookInfoData = (state, action) => {
    state.bookInfo = action.data;
    state.hasLoad = true;
    state.loading = false;
    return state;
};

const pushRecommendData = (state, action) => {
    state.recommend.data = action.data;
    state.recommend.hasLoad = true;
    return state;
};

const changeLoading = (state, action) => {
    state.loading = action.loading;
    return state;
};

const changeMoreIntro = (state, action) => {
    state.moreIntro = action.data;
    return state;
};

const changeUserBookCaseStatus = (state, action) => {
    if (state.bookInfo.user !== undefined) {
        state.bookInfo.user.inBookCase = action.data;
    }
    return state;
};

const downloadArticleProgress = (state, action) => {
    if (state.bookInfo.bookId) {
        // 当前的bookID必须为下载的bookID才允许更新
        if (state.bookInfo.bookId === action.bookId) {
            state.progress = action.data;
        }
    }
    return state;
};

export default (state = initState, action) => {
    const newState = JSON.parse(JSON.stringify(state));
    switch (action.type) {
    case DETAILS__PUSH_BOOK_INFO_DATA:
        return pushBookInfoData(newState, action);
    case DETAILS__CHANGE_LOADING:
        return changeLoading(newState, action);
    case DETAILS__CHANGE_MORE_INTRO:
        return changeMoreIntro(newState, action);
    case DETAILS__PUSH_RECOMMEND_DATA:
        return pushRecommendData(newState, action);
    case DETAILS__CHANGE_USER_BOOKCASE_STATUS:
        return changeUserBookCaseStatus(newState, action);
    case DETAILS__DOWNLOAD_ARTICLE_PROGRESS:
        return downloadArticleProgress(newState, action);
    default:
        return state;
    }
};
